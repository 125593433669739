import { Alert, ForbiddenPage, NotFoundPage } from '@elotech/components';
import AutenticarDocumento from 'itbi-cidadao/src/pages/autenticar-documento/AutenticarDocumento';
import FormLaudo from 'itbi-cidadao/src/pages/laudo/Form';
import ResumoLaudo from 'itbi-cidadao/src/pages/laudo/Resumo';
import { RouteWithRedirect } from 'itbi-common/components';
import DeclaracaoView from 'itbi-common/components/declaracao-itbi/DeclaracaoView';
import { MensagemCidadaoService, MenuItbiService } from 'itbi-common/service';
import { withService } from 'itbi-common/service';
import { permissaoCidadaoKeys } from 'itbi-common/utils/Permissoes';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';

import CarneListPage from '../pages/carne/CarneListPage';
import EmissaoCertidaoFormPage from '../pages/certidao/EmissaoCertidaoFormPage';
import ConcursoPremiadoFormPage from '../pages/concurso-premiado/ConcursoPremiadoFormPage';
import ContactPage from '../pages/contact/ContactPage';
import DeclaracaoContestacaoFormPage from '../pages/declaracao-itbi/contestacao/DeclaracaoContestacaoFormPage';
import DeclaracaoContestacaoTermoAceitePage from '../pages/declaracao-itbi/contestacao/DeclaracaoContestacaoTermoAceitePage';
import DeclaracaoForm from '../pages/declaracao-itbi/DeclaracaoForm';
import DeclaracaoListPage from '../pages/declaracao-itbi/DeclaracaoListPage';
import DocumentoEmitidoListPage from '../pages/documento-central/DocumentoEmitidoListPage';
import FaqList from '../pages/faq/FaqList';
import HomePage from '../pages/home/HomePage';
import LaudoListPage from '../pages/laudo/LaudoListPage';
import NotificacaoPage from '../pages/notificacao/NotificacaoPage';
import ParcelamentoFormPage from '../pages/parcelamento/novo/ParcelamentoFormPage';
import ParcelamentoListPage from '../pages/parcelamento/ParcelamentoListPage';
import ParcelamentoViewPage from '../pages/parcelamento/ParcelamentoViewPage';
import ProcessoAvaliacaoPage from '../pages/processo/ProcessoAvaliacaoPage';
import ProcessoForm from '../pages/processo/ProcessoForm';
import ProcessoListPage from '../pages/processo/ProcessoListPage';
import ProcessoViewPage from '../pages/processo/ProcessoViewPage';
import CadastrosPage from '../pages/propriedade/CadastrosPage';
import ExtratoListPage from '../pages/propriedade/extrato/ExtratoListPage';
import AuthorizationHistory from '../pages/user/AuthorizationHistory';
import MyAccountPage from '../pages/user/MyAccountPage';

const MyAccountRedirect = props => (
  <RouteWithRedirect redirectTo="/minhaconta" {...props} />
);

class Routes extends React.Component {
  static propTypes = {
    userExists: PropTypes.bool,
    currentUser: PropTypes.object
  };

  state = {
    permissoes: [],
    pending: true
  };

  hasPermission = key => {
    const permissao = this.state.permissoes.find(m => m.key === key);

    return permissao === undefined ? false : permissao.ativo;
  };

  componentDidMount() {
    this.loadPermissions();
    this.getMensagemCidadaoAtiva();
  }

  loadPermissions = async () => {
    try {
      const { data } = await this.props.menuItbiService.getMenuItbiAll();
      this.setState({ permissoes: data, pending: false });
    } catch (error) {
      this.setState({ pending: false });
    }
  };

  getMensagemCidadaoAtiva = async () => {
    MensagemCidadaoService.buscarMensgemCidadaoAtual()
      .then(response => {
        if (response.data) {
          Alert.warning({
            title: 'Aviso',
            text: response.data.mensagem,
            width: '60%'
          });
        }
      })
      .catch(error => {
        Alert.error('Erro ao buscar a mensagem de aviso do cidadão', error);
      });
  };

  render() {
    const { pending } = this.state;

    if (pending) {
      return null;
    }
    const { currentUser } = this.props;
    const shouldRedirect =
      !this.props.userExists || !currentUser?.aceitouUltimoTermo;

    return (
      <React.Fragment>
        <Switch>
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            path="/"
            component={HomePage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            path="/minhaconta"
            component={MyAccountPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            path="/notificacoes"
            component={NotificacaoPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.faq)}
            path="/faq"
            component={FaqList}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.history)}
            path="/history"
            component={AuthorizationHistory}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi"
            component={DeclaracaoListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi/novo"
            component={DeclaracaoForm}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi/:id"
            component={DeclaracaoForm}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi/:id/resumo"
            component={DeclaracaoView}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi/:id/contestar"
            component={DeclaracaoContestacaoFormPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.declaracaoItbi
            )}
            path="/declaracoes-itbi/:id/termo-aceite-contestacao"
            component={DeclaracaoContestacaoTermoAceitePage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.laudo)}
            path="/laudos"
            component={LaudoListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.laudo)}
            path="/laudos/novo"
            component={FormLaudo}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.laudo)}
            path="/laudos/:id"
            component={ResumoLaudo}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.autenticarDocumento
            )}
            path="/autenticar-documento"
            component={AutenticarDocumento}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.faleConosco)}
            path="/faleconosco"
            component={ContactPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.propriedades
            )}
            path="/propriedades"
            component={CadastrosPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.propriedades
            )}
            path="/propriedades/:tipoCadastro/:cadastroGeral/extrato"
            component={ExtratoListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.propriedades
            )}
            path="/carne"
            component={CarneListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.documentoEmitido
            )}
            path="/documentos-emitidos"
            component={DocumentoEmitidoListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={true}
            path="/parcelamentos"
            component={ParcelamentoListPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={true}
            path="/parcelamentos/:id/resumo"
            component={ParcelamentoViewPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={true}
            path="/parcelamentos/novo"
            component={ParcelamentoFormPage}
            currentUser={currentUser}
            exact
          />
          <MyAccountRedirect
            exact
            path="/emissao-certidoes"
            currentUser={currentUser}
            shouldRedirect={shouldRedirect}
            component={EmissaoCertidaoFormPage}
          />
          <MyAccountRedirect
            exact
            path="/processos"
            currentUser={currentUser}
            component={ProcessoListPage}
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.processos)}
          />
          <MyAccountRedirect
            exact
            path="/processos/novo"
            component={ProcessoForm}
            currentUser={currentUser}
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(permissaoCidadaoKeys.processos)}
          />
          <MyAccountRedirect
            exact
            component={ProcessoViewPage}
            currentUser={currentUser}
            shouldRedirect={shouldRedirect}
            path="/processos/:id/resumo"
            hasPermission={this.hasPermission(permissaoCidadaoKeys.processos)}
          />
          <MyAccountRedirect
            exact
            component={ProcessoAvaliacaoPage}
            currentUser={currentUser}
            shouldRedirect={shouldRedirect}
            path="/processos/:id/avaliar"
            hasPermission={this.hasPermission(permissaoCidadaoKeys.processos)}
          />
          <MyAccountRedirect
            shouldRedirect={shouldRedirect}
            hasPermission={this.hasPermission(
              permissaoCidadaoKeys.concursoPremiado
            )}
            path="/concurso-premiado"
            component={ConcursoPremiadoFormPage}
            currentUser={currentUser}
            exact
          />
          <Route path="/sem-permissao" component={ForbiddenPage} />
          <Route component={NotFoundPage} />
        </Switch>
      </React.Fragment>
    );
  }
}

const componentWithService = withService({
  menuItbiService: MenuItbiService
})(Routes);

export default withRouter(componentWithService);
