import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAuth, useSocket } from '../hooks';
import EloNotificationService from '../service/EloNotificationService';
import Alert from '../sweetalert/Alert';
import { EloNotification, TEloNotificationService, Tenant } from '../type';

type Props = {
  children: React.ReactNode;
  tenant?: Tenant;
  eloNotificationService?: TEloNotificationService;
  disableNotification?: boolean;
};

export type NotificationContextProps = {
  error?: string;
  countNaoLidas: number;
  notifications: EloNotification[];
  read(idNotification: string): void;
  readAll(): void;
  loadMore(): void;
  loading: boolean;
  disableNotification?: boolean;
};

export const NotificationContext = React.createContext<
  NotificationContextProps
>({
  error: undefined,
  countNaoLidas: 0,
  notifications: [],
  loading: false,
  read: () => {},
  readAll: () => {},
  loadMore: () => {},
  disableNotification: false
});

const EloNotificationProvider: React.FC<Props> = ({
  children,
  tenant,
  eloNotificationService = EloNotificationService
}) => {
  const [countNaoLidas, setCountNaoLidas] = useState(0);
  const [notifications, setNotifications] = useState<EloNotification[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  const { userProfile } = useAuth();
  const topic = useMemo(
    () =>
      tenant?.identifier && userProfile?.username
        ? `${tenant?.identifier}_${userProfile.username}`
        : '',
    [tenant, userProfile]
  );

  const socket = useSocket();

  const loadMore = useCallback(() => {
    console.warn('O módulo de notificação novo não carrega mais notificações');
  }, []);

  const getUnreadCount = useCallback(() => {
    eloNotificationService
      .getUnreadCount()
      .then(response => setCountNaoLidas(response.data.count))
      .catch(error => {
        Alert.error(
          {
            title: 'Erro ao buscar o número de notificações não lidas'
          },
          error
        );
      });
  }, []);

  const getNotifications = useCallback(() => {
    eloNotificationService
      .getNotifications()
      .then(response => setNotifications(response.data.content))
      .catch(error => {
        setError(error);
        Alert.error(
          {
            title: 'Erro ao buscar as notificações'
          },
          error
        );
      });
  }, []);

  const getData = useCallback(() => {
    setLoading(true);
    Promise.all([getNotifications(), getUnreadCount()]).finally(() =>
      setLoading(false)
    );
  }, [getNotifications, getUnreadCount]);

  useEffect(() => {
    console.log('getData');
    getData();
  }, [getData]);

  useEffect(() => {
    if (topic) {
      console.log('registering', { tenant, topic, userProfile });
      socket?.on(topic, () => {
        console.log('onMessage');
        getData();
      });
    }
  }, [topic, getData, socket]);

  const read = useCallback((idNotification: string) => {
    eloNotificationService.markAsRead([idNotification]).catch(error => {
      Alert.error({ title: 'Erro ao marcar a notificação como lida.' }, error);
    });
  }, []);

  const readAll = useCallback(() => {
    const ids = notifications.map(
      (notification: EloNotification) => notification.id
    );
    eloNotificationService.markAsRead(ids).catch(error => {
      Alert.error({ title: 'Erro ao marcar a notificação como lida.' }, error);
    });
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        countNaoLidas,
        error,
        notifications,
        read,
        readAll,
        loadMore,
        loading
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default EloNotificationProvider;
