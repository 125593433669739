import React, { useCallback, useEffect, useState } from 'react';

import DialogBox from '../dialogBox/DialogBox';
import * as ActuatorService from '../service/ActuatorService';
import { EnumTypeDialog } from '../type';

const ONE_MINUTE_IN_MS = 60_000;

type Props = {
  actuatorBaseUrl: string;
};

const VersionModal: React.FC<Props> = ({ actuatorBaseUrl = '' }) => {
  const [oldVersion, setOldVersion] = useState('');
  const [currentVersion, setCurrentVersion] = useState('');

  const checkVersionUpdate = useCallback(() => {
    ActuatorService.getInfo(actuatorBaseUrl)
      .then(({ data }) => {
        const version = data?.build?.version;

        if (!oldVersion) {
          return setOldVersion(version);
        }

        if (oldVersion && version !== oldVersion) {
          return setCurrentVersion(version);
        }
      })
      .catch(error => console.log(error));
  }, [actuatorBaseUrl, oldVersion]);

  useEffect(() => {
    const interval = setInterval(() => checkVersionUpdate(), ONE_MINUTE_IN_MS);
    return () => clearInterval(interval);
  }, [checkVersionUpdate]);

  return (
    <DialogBox
      title={'Versão do sistema atualizada'}
      type={EnumTypeDialog.Error}
      message={`Reinicie a página para evitar divergência de dados\n Versão atual: ${oldVersion} - Versão nova: ${currentVersion}`}
      show={!!currentVersion}
      successTitleButton={'Reiniciar (F5)'}
      showCancelButton
      cancelTitleButton={'Não reiniciar'}
      onCancel={() => setCurrentVersion('')}
      onSuccess={() => window.location.reload()}
    />
  );
};

export default VersionModal;
