import React from 'react';

import { NotificationContext } from './EloNotificationProvider';

type Props = {
  children: React.ReactNode;
};

const DisabledNotificationProvider: React.FC<Props> = ({ children }) => (
  <NotificationContext.Provider
    value={{
      countNaoLidas: 0,
      error: undefined,
      notifications: [],
      read: () => {},
      readAll: () => {},
      loadMore: () => {},
      loading: false,
      disableNotification: true
    }}
  >
    {children}
  </NotificationContext.Provider>
);

export default DisabledNotificationProvider;
