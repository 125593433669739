import React, { useEffect, useState } from 'react';

import { EnumTypeDialog } from '../type/EnumTypeDialog';

type Props = {
  title: string;
  type: EnumTypeDialog; // module-color (Informativo), positive (Sucesso), neutral, negative (Error), warning (Informação)
  successTitleButton?: string;
  cancelTitleButton?: string;
  message: string;
  showCancelButton?: boolean;
  moreDetails?: string;
  onCancel?: Function;
  onSuccess?: Function;
  show: boolean;
};

const DialogBox: React.FunctionComponent<Props> = ({
  title,
  type,
  successTitleButton,
  cancelTitleButton,
  message,
  showCancelButton,
  moreDetails,
  onSuccess,
  onCancel,
  show
}) => {
  useEffect(() => {
    setShowDialog(show);
  }, [show]);
  const [openMessage, setOpenMessage] = useState(false);
  const [showDialog, setShowDialog] = useState(show);
  return (
    <>
      <div className={`dialoguebox ${type} ${showDialog && 'open'}`}>
        <div className="message">
          <h3 className="title"> {title} </h3>
          {message}
          {moreDetails && (
            <>
              <button
                className="more-message-opener"
                onClick={() => {
                  setOpenMessage(!openMessage);
                }}
              >
                Mais Detalhes
              </button>
              <p className={`more-message mt-xs ${openMessage && 'open'}`}>
                {moreDetails}
              </p>
            </>
          )}
        </div>

        {showCancelButton && (
          <button
            className="btn text neutral inline"
            onClick={() => {
              setShowDialog(false);
              if (onCancel) {
                onCancel();
              }
            }}
          >
            {cancelTitleButton !== undefined ? cancelTitleButton : 'Cancelar'}
          </button>
        )}

        <button
          className={`btn inline ${type}`}
          onClick={e => {
            setShowDialog(false);
            if (onSuccess) {
              onSuccess();
            }
          }}
        >
          {successTitleButton !== undefined ? successTitleButton : 'Aceitar'}
        </button>
      </div>
    </>
  );
};

export default DialogBox;
