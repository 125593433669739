import axios, { AxiosPromise } from 'axios';

import { NotificationNotCountDto, PagedResponse } from '../type';

const EloNotificationCidadaoService = {
  getNotifications: (): AxiosPromise<PagedResponse<any>> =>
    axios.get(`/painel-api/api/notifications`, {
      params: {
        size: 5,
        page: 0,
        sort: 'createOn,desc',
        search: 'cidadao==true'
      }
    }),

  getUnreadCount: (): AxiosPromise<NotificationNotCountDto> =>
    axios.get(`/painel-api/api/notifications/count-not-readed`, {
      params: {
        cidadao: true
      }
    }),

  markAsRead: (ids: string[]): AxiosPromise<void> =>
    axios.put(`/painel-api/api/notifications`, ids),

  getWebsocketURL: undefined
};

export default EloNotificationCidadaoService;
