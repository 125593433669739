import { Sort } from '@elotech/components';
import axios, { AxiosPromise } from 'axios';

import { GerarDebitoDTO, Usuario } from '../type';
import ServiceUtils from '../utils/ServiceUtils';

export const defaultSort = { sort: 'numero,data,desc' };

export const loadDeclaracoesByRequerente = (
  searchParams: string,
  page: object
): AxiosPromise<any> =>
  axios.get(
    `/api/declaracoes-itbi/requerente?${ServiceUtils.buildSearchParams(
      searchParams
    )}`,
    {
      params: { ...defaultSort, ...page }
    }
  );

export const loadDeclaracoesById = (id: string): AxiosPromise<any> =>
  axios.get(`/api/declaracoes-itbi/${id}`);

export const loadDeclaracoesItbi = (
  searchParams: string,
  page: object,
  sort?: Sort
): any => {
  sort = sort || defaultSort;
  return axios.get(
    `/api/declaracoes-itbi?${ServiceUtils.buildSearchParams(searchParams)}`,
    {
      params: { ...sort, ...page }
    }
  );
};

export const findDeclaracaoItbiById = (id: string): AxiosPromise<any> =>
  axios.get(`/api/declaracoes-itbi/${id}`);

export const salvarRascunho = (declaracao: any): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/rascunho`, declaracao);

export const updateRascunho = (declaracao: any): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/rascunho/${declaracao.id}`, declaracao);

export const updateVendedores = (
  declaracao: string,
  vendedor: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${declaracao}/vendedores`, vendedor);

export const deleteVendedores = (
  idRascunho: string,
  idVendedor: string
): AxiosPromise<any> =>
  axios.delete(`/api/declaracoes-itbi/${idRascunho}/vendedores/${idVendedor}`);

export const updateCompradores = (
  declaracao: string,
  comprador: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${declaracao}/compradores`, comprador);

export const deleteCompradores = (
  idRascunho: string,
  idComprador: string
): AxiosPromise<any> =>
  axios.delete(
    `/api/declaracoes-itbi/${idRascunho}/compradores/${idComprador}`
  );

export const updateAnuentes = (
  declaracao: string,
  anuente: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${declaracao}/anuentes`, anuente);

export const deleteAnuentes = (
  idRascunho: string,
  idAnuente: string
): AxiosPromise<any> =>
  axios.delete(`/api/declaracoes-itbi/${idRascunho}/anuentes/${idAnuente}`);

export const definirPrincipal = (
  id: string,
  proprietario: any
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${id}/definir-principal/${proprietario.id}`);

export const salvarDeclaracao = (declaracao: any): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${declaracao.id}`);

export const gerarDebito = (declaracao: any): AxiosPromise<GerarDebitoDTO> =>
  axios.post(`/api/declaracoes-itbi/${declaracao.id}/gerar-debito`);

export const imprimirCertidaoQuitacao = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/gera-certidao-quitacao`);

export const salvarDocumentos = (
  id: string,
  documentos: object[]
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${id}/documentos`, documentos);

export const assumirResponsabilidade = (id: string): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${id}/assumir`);

export const update = (id: string, declaracao: object): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${id}`, declaracao);

export const notificarCidadao = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/notificar`);

export const getProprietarios = (id: string): AxiosPromise<any> =>
  axios.get(`/api/declaracoes-itbi/${id}/proprietarios`);

export const deleteProprietarios = (
  idDeclaracao: string,
  idProprietario: string
): AxiosPromise<any> =>
  axios.delete(
    `/api/declaracoes-itbi/${idDeclaracao}/proprietarios/${idProprietario}`
  );

export const updateProprietarios = (
  declaracao: string,
  proprietario: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${declaracao}/proprietarios`, proprietario);

export const deferir = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/deferir`);

export const gerarBoleto = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/gerar-bloqueto`);

export const retificar = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/retificar`);

export const transferir = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/transferir`);

export const transferirSemPagamento = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/transferir-sem-pagamento`);

export const cancelar = (id: string, motivo: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/cancelar`, { motivo });

export const indeferir = (
  id: string,
  motivoIndeferimento: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/${id}/indeferir`, motivoIndeferimento);

export const indeferirByContestacao = (
  id: string,
  motivoIndeferimento: object
): AxiosPromise<any> =>
  axios.put(
    `/api/declaracoes-itbi/${id}/indeferir-contestacao`,
    motivoIndeferimento
  );

export const togglePrioritario = (
  id: string,
  motivoPrioritario: object
): AxiosPromise<any> =>
  axios.put(`/api/declaracoes-itbi/prioritario/${id}`, motivoPrioritario);

export const transferirResponsavel = (
  idDeclaracao: string,
  responsavel: Usuario
): AxiosPromise<any> =>
  axios.put(
    `/api/declaracoes-itbi/${idDeclaracao}/transferir-responsavel`,
    responsavel
  );

export const restaurarSegmentos = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/restaurar-segmentos`);

export const copiarDeclaracao = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/copiar`);

export const aceitarContestacaoDeclaracao = (id: string): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/aceitar-contestacao`);

export const contestarDeclaracao = (
  id: string,
  value: any
): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${id}/contestar`, value);

export const finalizarContestacao = (value: any): AxiosPromise<any> =>
  axios.post(`/api/declaracoes-itbi/${value?.id}/finalizar-contestacao`, value);

export const remove = (id: string): AxiosPromise<any> =>
  axios.delete(`/api/declaracoes-itbi/${id}`);

export const getHistory = (id: string): AxiosPromise<any> =>
  axios.get(`/api/declaracoes-itbi/${id}/history`);

export const addDocumentoAvulso = (
  idDeclaracao: string,
  documento: object
): AxiosPromise<any> =>
  axios.post(
    `/api/declaracoes-itbi/${idDeclaracao}/documentos-avulsos`,
    documento
  );
export const removeDocumentoAvulso = (
  idDeclaracao: string,
  idDocumento: string
): AxiosPromise<any> =>
  axios.delete(
    `/api/declaracoes-itbi/${idDeclaracao}/documentos-avulsos/${idDocumento}`
  );

export const apagarGuiaPagamento = (idDeclaracao: string): AxiosPromise<any> =>
  axios.delete(`/api/declaracoes-itbi/${idDeclaracao}/guiapagamento`);

export const imprimirDocumentoIsencao = (id: string): AxiosPromise<any> =>
  axios.patch(`/api/certidoes/${id}/gerar-documento-isencao`);

export const hasDeclaracaoItbiAguardandoAceite = (): AxiosPromise<boolean> =>
  axios.get(`/api/declaracoes-itbi/has-declaracao-aguardando-aceite`);

export const updateDataVizualizacaoContestacao = (idDeclaracao: string) => {
  return axios.put(
    `/api/declaracoes-itbi/${idDeclaracao}/update-data-visualizacao-contestacao`
  );
};

export const verificaDuplicidade = (id: string): AxiosPromise<any> =>
  axios.get(`/api/declaracoes-itbi/verifica-duplicidade/${id}`);
