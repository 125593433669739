import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

import Icon from '../icons/Icon';
import {
  NotificationContext,
  NotificationContextProps
} from '../providers/EloNotificationProvider';
import { EloNotification } from '../type';
import Header from './Header';
import NotificationItem from './notifications/NotificationItem';

type Props = {};

const EloNotificationContent: React.FC<Props> = () => {
  const { activeNotification, toggleNotification } = useContext(
    Header.HeaderContext
  );
  const [selectedNotification, setSelectedNotification] = useState<
    EloNotification
  >();
  const { notifications, read }: NotificationContextProps = useContext(
    NotificationContext
  );

  const history = useHistory();

  const selectNotification = (notification: EloNotification) => {
    setSelectedNotification(notification);
    read(notification.id);
  };

  const redirectToLink = (notification: EloNotification) => {
    setSelectedNotification(undefined);
    toggleNotification();
    window.open(notification.link, '_blank');
  };

  const renderSelectedNotification = (notification: EloNotification) => {
    return (
      <NotificationItem
        key={notification.id}
        notification={notification}
        onSelect={redirectToLink}
        showDetail
      />
    );
  };

  const renderNotifications = (notifications: EloNotification[]) => {
    if (notifications.length === 0) {
      return (
        <li>
          <article>
            <p id="emptyList">Você não possui notificações</p>
          </article>
        </li>
      );
    }
    return (
      <>
        {notifications.map(notification => (
          <NotificationItem
            key={notification.id}
            notification={notification}
            onSelect={selectNotification}
          />
        ))}
        <li className="more">
          <button onClick={goToNotificationScreen}>
            Ver Todas <em className="fa fa-chevron-down" />
          </button>
        </li>
      </>
    );
  };

  const goToNotificationScreen = () => {
    history.push('/notificacoes');
    toggleNotification();
  };

  if (!activeNotification) {
    return null;
  }

  return (
    <div className="features-notifications reduced auto-height read-options active">
      <h2 className="title">
        {selectedNotification && (
          <div
            id="divBack"
            onClick={() => setSelectedNotification(undefined)}
            className="read hint clean center-left neutral"
          >
            <Icon icon={'chevron-left'} />
            <div className="hint-content">Voltar</div>
          </div>
        )}
        Notificações
      </h2>
      <div className="features-body">
        <ul className="notification-list">
          {selectedNotification
            ? renderSelectedNotification(selectedNotification)
            : renderNotifications(notifications)}
        </ul>
      </div>
    </div>
  );
};

export default EloNotificationContent;
